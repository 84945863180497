body {
  text-align: center;
}
main {
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem;
}
img {
  max-width: 40%;
  max-height: 40%;
}

.add-new-product {
  text-align: center;

  h2 {
    margin-bottom: 1.5rem;
  }

  .form-container {
    display: flex;
    justify-content: center;

    form {
      width: 400px;
      padding: 2rem;
      border: 1px solid #ccc;
      border-radius: 4px;

      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        label {
          margin-bottom: 0.5rem;
          font-weight: bold;
        }

        input {
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }

      button {
        padding: 0.5rem 1rem;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
